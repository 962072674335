/* eslint-disable */
import React from 'react';
import style from './style.module.scss'
import { FormattedMessage, useIntl } from 'react-intl';
import HowCell from './HowCell';
import { setTheme } from 'newui/Utils/themeUtils';
import HowTable from './HowTable';

function LoyaltyHow(props) {

    const intl = useIntl()

    const rowTitles = [
        intl.formatMessage({ id: 'newcasino.loyalty.how.para.6.table.head.1', defaultMessage: 'For every 5 EUR Wagered' }),
        intl.formatMessage({ id: 'newcasino.loyalty.how.para.6.table.head.2', defaultMessage: 'PNG Reward Points' }),
        intl.formatMessage({ id: 'newcasino.loyalty.how.para.6.table.head.3', defaultMessage: 'Progress Points' })
    ]
    const rowValues = [
        [
            intl.formatMessage({ id: 'newcasino.loyalty.how.para.6.table.value.1', defaultMessage: 'For every 5 EUR Wagered' }),
            intl.formatMessage({ id: 'newcasino.loyalty.how.para.6.table.value.2', defaultMessage: 'PNG Reward Points' }),
            intl.formatMessage({ id: 'newcasino.loyalty.how.para.6.table.value.3', defaultMessage: 'Progress Points' })
        ]
    ]

    const rowTitles2 = [
        intl.formatMessage({ id: 'newcasino.loyalty.how.para.7.table.head.1', defaultMessage: 'For every 50 EUR Wagered' }),
        intl.formatMessage({ id: 'newcasino.loyalty.how.para.7.table.head.2', defaultMessage: 'PNG Reward Points' }),
        intl.formatMessage({ id: 'newcasino.loyalty.how.para.7.table.head.3', defaultMessage: 'Progress Points' })
    ]
    const rowValues2 = [
        [
            intl.formatMessage({ id: 'newcasino.loyalty.how.para.7.table.value.1', defaultMessage: 'Live Dealer Games' }),
            intl.formatMessage({ id: 'newcasino.loyalty.how.para.7.table.value.2', defaultMessage: '1' }),
            intl.formatMessage({ id: 'newcasino.loyalty.how.para.7.table.value.3', defaultMessage: '1' })
        ],
        [
            intl.formatMessage({ id: 'newcasino.loyalty.how.para.7.table.value.4', defaultMessage: 'Table Games' }),
            intl.formatMessage({ id: 'newcasino.loyalty.how.para.7.table.value.5', defaultMessage: '1' }),
            intl.formatMessage({ id: 'newcasino.loyalty.how.para.7.table.value.6', defaultMessage: '1' })
        ],
        [
            intl.formatMessage({ id: 'newcasino.loyalty.how.para.7.table.value.7', defaultMessage: 'Crash Games' }),
            intl.formatMessage({ id: 'newcasino.loyalty.how.para.7.table.value.8', defaultMessage: '1' }),
            intl.formatMessage({ id: 'newcasino.loyalty.how.para.7.table.value.9', defaultMessage: '1' })
        ]
    ]
    return (
        <>
            <div className={style.title}>
                {intl.formatMessage({ id: 'newcasino.loyalty.how.title', defaultMessage: 'How does it works?' })}
            </div>
            <div className={style.splitInTwo}>
                <div className={style.columnOne}>
                    <HowCell image={setTheme('https://nyc3.digitaloceanspaces.com/playnumbersgame/general/newui/themes/{THEME}/Loyalty/IMG_Chips.png')} title={intl.formatMessage({ id: 'newcasino.loyalty.how.para.1.title', defaultMessage: 'PNG Reward Points' })} txt={intl.formatMessage({ id: 'newcasino.loyalty.how.para.1.txt', defaultMessage: 'All the points you can use to exchange for Real Money, Free Spins or even Real Money with a wager requirement.' })} />
                    <HowCell image={setTheme('https://nyc3.digitaloceanspaces.com/playnumbersgame/general/newui/themes/{THEME}/Loyalty/IMG_Meter.png')} title={intl.formatMessage({ id: 'newcasino.loyalty.how.para.2.title', defaultMessage: 'Progress Points' })} txt={intl.formatMessage({ id: 'newcasino.loyalty.how.para.2.txt', defaultMessage: 'Progress points determine the Numbers Game VIP Level you are in.' })} />
                    <HowCell image={setTheme('https://nyc3.digitaloceanspaces.com/playnumbersgame/general/newui/themes/{THEME}/Loyalty/IMG_Store.png')} title={intl.formatMessage({ id: 'newcasino.loyalty.how.para.3.title', defaultMessage: 'PNG Point Shop' })} txt={intl.formatMessage({ id: 'newcasino.loyalty.how.para.3.txt', defaultMessage: 'You can trade in your PNG Reward Points here for the option that fits you best: Real Money, Free Spins, or Real Money with a wager requirement.' })} txtSmall={intl.formatMessage({ id: 'newcasino.loyalty.how.para.3.txtextra', defaultMessage: '*If you exchange your PNG Reward Points for real money with a wager requirement, please note that the wagering requirement decreases by 5X at each new VIP level for the same price.' })} />
                    <HowCell image={setTheme('https://nyc3.digitaloceanspaces.com/playnumbersgame/general/newui/themes/{THEME}/Loyalty/IMG_Crown.png')} title={intl.formatMessage({ id: 'newcasino.loyalty.how.para.4.title', defaultMessage: 'Numbers Game VIP Levels' })} txt={intl.formatMessage({ id: 'newcasino.loyalty.how.para.4.txt', defaultMessage: '' })} />
                </div>
                <div className={style.columnTwo}>
                    <HowCell image={setTheme('https://nyc3.digitaloceanspaces.com/playnumbersgame/general/newui/themes/{THEME}/Loyalty/IMG_24-Hours.png')} title={intl.formatMessage({ id: 'newcasino.loyalty.how.para.5.title', defaultMessage: 'Daily consecutive Login Rewards' })} txt={intl.formatMessage({ id: 'newcasino.loyalty.how.para.4.txt', defaultMessage: 'By earning a minimum of 500 Progress Points each month, you unlock your daily consecutive login rewards for the following month. With each passing day and as you advance through the VIP Levels, superior rewards will be available to you. The more you play, the better the level of prizes.' })} />
                    <HowTable title={intl.formatMessage({ id: 'newcasino.loyalty.how.para.5.title', defaultMessage: 'Daily consecutive Login Rewards' })} rowTitles={rowTitles} rowValues={rowValues} />
                    <HowTable rowTitles={rowTitles2} rowValues={rowValues2} />
                </div>
            </div>
        </>
    );
}

export default LoyaltyHow;
