import React, { useEffect, useState } from 'react';

import Button from 'newui/Generic/Inputs/Button';
import style from './style.module.scss'
import { useLocation, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl'
import * as jwtBackendService from 'newui/Services/JWTBackend/jwtBackendService'
import { displayGenericErrorNotificationIntl, displayInfoNotificationIntl } from 'newui/Utils/uiUtil';
import { getImageUrl } from 'newui/Utils/uiUtil';

function Documents(props) {

    const navigate = useNavigate();
    const location = useLocation();
    const intl = useIntl();

    let [imgSrcTempo, setImgSrcTempo] = useState(getImageUrl('newui/general/noImage.png'))
    let [imgSrc2Tempo, setImgSrc2Tempo] = useState(getImageUrl('newui/general/noImage.png'))

    let [imgSrc, setImgSrc] = useState(null)
    let [imgFormat, setImgFormat] = useState(null)
    let [img2Src, setImg2Src] = useState(null)
    let [img2Format, setImg2Format] = useState(null)

    useEffect(() => {
        loadImage(1);
        loadImage(2);
    },// eslint-disable-next-line
        [])

    function loadImage(type) {
        let succ = loadImageSuccess;
        let fail = loadImageFailure;
        setImgSrcTempo(getImageUrl('newui/general/loading3.gif'))

        if (type === 2) {
            succ = loadImage2Success;
            fail = loadImage2Failure;
            setImgSrc2Tempo(getImageUrl('newui/general/loading3.gif'))
        }
        jwtBackendService.getUserImageFile(props.personalData.id, type, succ, fail, navigate, location.pathname);
    }

    function loadImageSuccess(data) {
        if (data.doc64) {
            setImgSrc(Buffer.from(data.doc64, "base64").toString());
            setImgFormat(data.format);
        } else {
            setImgSrcTempo(getImageUrl('newui/general/noImage.png'))
        }
    }

    function loadImageFailure(data) {
        displayGenericErrorNotificationIntl(intl);
    }

    function loadImage2Success(data) {
        if (data.doc64) {
            setImg2Src(Buffer.from(data.doc64, "base64").toString());
            setImg2Format(data.format);
        } else {
            setImgSrc2Tempo(getImageUrl('newui/general/noImage.png'))
        }
    }

    function loadImage2Failure(data) {
        displayGenericErrorNotificationIntl(intl);
    }

    function getImageSrc(type) {
        let toReturn = null;

        if (type === 1) {
            toReturn = imgSrcTempo
        } else {
            toReturn = imgSrc2Tempo
        }

        if (type === 1 && imgSrc && imgFormat) {
            toReturn = "data:" + imgFormat + ";base64," + imgSrc

        } else if (type === 2 && img2Src && img2Format) {
            toReturn = "data:" + img2Format + ";base64," + img2Src
        }

        return toReturn;
    }


    function approve() {
        jwtBackendService.approveKyc(props.personalData.id, succApprove, fail, navigate, location.pathname);
    }

    function decline() {
        jwtBackendService.rejectKyc(props.personalData.id, succDecline, fail, navigate, location.pathname);
    }

    function succApprove(data) {
        displayInfoNotificationIntl(intl, 'newcasino.admin.kyc.approved');
        props.reload();
    }

    function succDecline(data) {
        displayInfoNotificationIntl(intl, 'newcasino.admin.kyc.reject');
        props.reload();
    }

    function fail() {
        displayGenericErrorNotificationIntl(intl);
    }

    function openLegacy(image) {
        window.open('https://playnumbersgame.com/api/publics/image?i=' + image, '_blank').focus();
    }

    function swipe(theId) {
        var image = new Image();
        image.src = getImageSrc(theId);
        image.style = 'max-width: 80%;height: auto;';
        image.id = 'theImage';

        var w = window.open("");
        w.document.write('<script>let rot = 0; function rotate(){rot = rot + 90; document.getElementById("theImage").style.transform = "rotate("+rot+"deg)" ;}</script>');
        w.document.write('<div onClick="rotate();" style="cursor: pointer; height: 100%; display: flex; align-items: center; justify-content:center; max-width: 100%;">' + image.outerHTML + '</div>');
    }

    return (
        <>
            {(props.personalData.id_proof_image_1 !== null || props.personalData.id_proof_image_2 !== null) &&
                <>
                    <div className={style.labelTitle}>{intl.formatMessage({ id: 'newcasino.profile.password.doc.legacy', defaultMessage: 'Legacy Image' })}</div>
                    {(props.personalData.id_proof_image_1 !== null) && <div className={style.thePointer} onClick={() => openLegacy(props.personalData.id_proof_image_1)}>{props.personalData.id_proof_image_1}</div>}
                    {(props.personalData.id_proof_image_2 !== null) && <div className={style.thePointer} onClick={() => openLegacy(props.personalData.id_proof_image_2)}>{props.personalData.id_proof_image_2}</div>}
                    <div className={style.labelTitle}>{intl.formatMessage({ id: 'newcasino.profile.password.doc.newimages', defaultMessage: 'New Images' })}</div>
                </>
            }
            <div className={style.imagesGrid}>
                <div>
                    <div className={style.allCenter}><div className={style.photoTitle}>{intl.formatMessage({ id: 'newcasino.profile.password.doc.proof1.title', defaultMessage: '' })}</div></div>
                    <div className={style.allCenter}><div className={style.photoSubTitle}>{intl.formatMessage({ id: 'newcasino.profile.password.doc.proof1.desc', defaultMessage: '' })}</div></div>
                    <div className={style.allCenter}><div className={style.imageDiv}><img id='img1' onClick={() => swipe(1)} className={style.theImage} src={getImageSrc(1)} alt='' /></div></div>
                </div>
                <div>
                    <div className={style.allCenter}><div className={style.photoTitle}>{intl.formatMessage({ id: 'newcasino.profile.password.doc.proof2.title', defaultMessage: '' })}</div></div>
                    <div className={style.allCenter}><div className={style.photoSubTitle}>{intl.formatMessage({ id: 'newcasino.profile.password.doc.proof2.desc', defaultMessage: '' })}</div></div>
                    <div className={style.allCenter}><div className={style.imageDiv}><img id='img2' onClick={() => swipe(2)} className={style.theImage} src={getImageSrc(2)} alt='' /></div></div>
                </div>
            </div>
            <div className={style.buttCenter}>
                <div className={style.grid50}>
                    <Button value={intl.formatMessage({ id: 'newcasino.profile.password.doc.proof1.approve', defaultMessage: 'Approve' })} colorStyle='light' onClick={approve} />
                    <Button value={intl.formatMessage({ id: 'newcasino.profile.password.doc.proof1.decline', defaultMessage: 'Decline' })} onClick={decline} />
                </div>
            </div>
        </>
    );
}

export default Documents;