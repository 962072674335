import React, { useState } from 'react'
import style from './style.module.scss'
import { isNumeric, isText } from 'newui/Utils/uiUtil'
import TextField from 'newui/Generic/Inputs/TextField';
import Checkbox from 'newui/Generic/Inputs/Checkbox';
import Button from 'newui/Generic/Inputs/Button';

function CategoryEditor(props) {
  const [id] = useState(props.category ? props.category.id : -1);
  const [active, setActive] = useState(props.category ? props.category.active : 0)
  const [category, setCategory] = useState(props.category ? props.category.category : '')
  const [code, setCode] = useState(props.category ? props.category.code : '')
  const [orderid, setOrderId] = useState(props.category ? props.category.orderid : '')
  const [type, setType] = useState(props.category ? props.category.type : '')
  const [wageringpercent, setWageringpercent] = useState(props.category ? props.category.wageringpercent : '')
  const [error, setError] = useState('');

  function validate(clone) {
    return isText(clone.category) && isText(clone.code) && isNumeric(clone.orderid) && isText(clone.type) && isNumeric(clone.wageringpercent);
  }

  function onSave() {
    var toClone = props.category ? props.category : {}
    var clone = JSON.parse(JSON.stringify(toClone))

    clone.id = id;
    clone.active = active;
    clone.category = category;
    clone.code = code;
    clone.orderid = orderid;
    clone.type = type;
    clone.wageringpercent = wageringpercent;

    if (validate(clone)) {
      props.closeModal();

      var action = 'MODIFY';
      if (props.index === -1) {
        action = 'ADD'
      }

      props.callback(clone, props.index, action);
    } else {
      setError('Validation Error');
    }
  }

  function onDelete() {
    props.closeModal();
    props.callback(props.category, props.index, 'DELETE_CAT');
  }

  function toggleActive(value) {
    if (value === false) {
      setActive(0)
    } else {
      setActive(1)
    }
  }

  try {

    return (
      <>
        <div className='topModal_pops topModal_title'>
          <h4 className='topModal_rg-heads'>
            Category Editor
          </h4>
        </div>
        <div>
          <div>
            <div className={style.bottomPad}><TextField autofocus='true' values={[category]} label={'Name'} onChange={setCategory} /></div>
            <div className={style.bottomPad}><TextField values={[code]} label={'Code'} onChange={setCode} /></div>
            <div className={style.bottomPad}><TextField values={[type]} label={'Type'} onChange={setType} /></div>
            <div className={style.bottomPad}><TextField type='digit' values={[orderid]} label={'Order Id'} onChange={setOrderId} /></div>
            <div className={style.bottomPad}><TextField type='digit' values={[wageringpercent]} label={'Wagering Percent'} onChange={setWageringpercent} /></div>
            <div className={style.bottomPad}><Checkbox label={'Active'} checked={(active === 1)} onClick={toggleActive} /></div>
            <div className={style.twoColGrid50}>
              <div><Button width='100px' colorStyle='dark' value={'Save'} onClick={onSave} /></div>

              {(props.index > -1) &&
                <div><Button width='100px' colorStyle='light' value={'Delete'} onClick={onDelete} /></div>

              }
            </div>
          </div>
          {error}
        </div>


      </>
    );

  } catch (error) {
    console.log('ERROR: ' + error.message);
    return '';
  }
}

export default CategoryEditor