import React, { useState, useEffect } from 'react'
import { ConfigProvider } from 'antd'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { IntlProvider } from 'react-intl'
import { saveToLocalSetting, getFromLocalSetting } from 'newui/Utils/uiUtil'
import { canAccess } from 'newui/Utils/authorizationUtils'
import { SnackbarProvider } from 'notistack';
import { closeSnackbar } from 'notistack'
import * as constants from 'newui/constants.js'
import * as backEndService from 'newui/Services/Backend/backendService';
import english from 'newui/locales/en-US'
import french from 'newui/locales/fr-FR'
import portuguese from 'newui/locales/pt-BR'
import style from './style.module.scss'
import moment from 'moment';

//API
import ApiPersistence from 'newui/Utils/ApiPersistence';
import ApiCache from 'newui/Utils/ApiCache';

//MENUS
import UserMenu from './General/Menus/UserMenu';
import WalletsMenu from './General/Menus/WalletsMenu';
import TopStickyMenu from './General/Menus/TopStickyMenu';
import BorderMenu from './General/Menus/BorderMenu';
import MobileBottomMenu from './General/Menus/MobileBottomMenu';

//AUTHENTICATION
import Login from './General/Authentication/Login';
import Register from './General/Authentication/Register';
import Logout from './General/Authentication/Logout';

//MODAL
import DefaultModal from './General/Modal/DefaultModal';
import ModalSearchController from './General/Modal/ModalSearchController';

//HOME
import Home from './General/Home';

//ADMIN PAGES
import MaintenancePlayer from './Administration/Maintenance';
import ContestList from './Administration/ContestWinners/list';
import InfoPopupList from './Administration/InfoPopup/list';
import EditInfoPopup from './Administration/InfoPopup';
import EditContest from './Administration/ContestWinners';
import ManageGames from './Administration/ManageGames';
import UserManagement from './Administration/UserManagement';

//PLAYERS
import ResponsibleGambling from './Players/ResponsibleGaming';
import ShowContestWinners from './Players/ContestWinners';
import Cashier from './Players/Cashier';
import DepositLimit from './Players/ResponsibleGaming/depositlimit';
import LossLimit from './Players/ResponsibleGaming/losslimit';
import TakeABreak from './Players/ResponsibleGaming/takeabreak';
import ActivityCheck from './Players/ResponsibleGaming/activitycheck';
import SelfExclusion from './Players/ResponsibleGaming/selfexclusion';
import ReferFriendWager from './Players/ReferFriendWager';
import Profile from './Players/Profile';

//STREAMERS
import StreamerActions from './Streamers/StreamerActions';

//GENERAL
import PlayGame from './General/Games';
import UserdataWrapper from './General/Wrapper/UserdataWrapper';
import GenericTest from 'newui/Generic/Inputs/GenericTest';
import PageFooter from './General/PageFooter';

import ContactUs from './General/ContactUs';
import AboutUs from './General/AboutUs';
import BettingRules from './General/BettingRules';
import BonusTerms from './General/BonusTerms';
import FAQ from './General/FAQ';
import PrivacyPolicy from './General/PrivacyPolicy';
import TermsConditions from './General/TermsConditions';
import System404 from './General/System404';
import System500 from './General/System500';
import GiveAways from './Administration/Giveaways';
import Promotions from './Administration/Promotions';
import SystemResetPassword from './General/Authentication/ResetPassword';
import Loyalty from './Players/Loyalty';
import Promotion from './Administration/Promotions/PromotionViewer';
import NewPromotion from './Administration/Promotions/NewPromotion';


function HomePage(props) {

  const ACCESS_PUBLIC = 'public'
  const ACCESS_PRIVATE = 'private'

  //const USERTYPE_PLAYER = 1;
  //const USERTYPE_AFFILIATE = 2;
  //const USERTYPE_PARTNER = 3;
  const USERTYPE_ADMIN = 4;
  const USERTYPE_STREAMER = 5;

  const [displayLeftMenu, checkDisplayLeftMenu] = useState(showLeftMenu());
  const [reloadTopMenu, setReloadTopMenu] = useState(Math.random());
  const [displayUserMenu, setDisplayUserMenu] = useState(false);
  const [maintenance, setMaintenance] = useState(false);
  const [maintenanceAllowLogin, setMaintenanceAllowLogin] = useState(false);
  const [displayWalletsMenu, setDisplayWalletsMenu] = useState(false);
  const [reloadBorderMenu, setReloadBorderMenu] = useState(Math.random());
  const [lang, setLang] = useState(ApiPersistence.getInstance().getLanguage())
  const [hideMenuForFullScreen, setHideMenuForFullScreen] = useState(false);
  const [simulateForFullScreenOrigin, setSimulateForFullScreenOrigin] = useState(constants.STRING_EMPTY);

  const jsonConfigurationLocation = '/resources/newui/config/configs.json';
  const locales = {
    'en-US': english,
    'fr-FR': french,
    'pt-BR': portuguese,
  }

  var currentLocale = locales[lang];

  var renderLoopInProgress = false;
  const [searchMsg, setSearchMsg] = useState(
    {
      id: -1,
      title1: constants.STRING_EMPTY,
      title2: constants.STRING_EMPTY,
      message: constants.STRING_EMPTY,
      image: null,
    }
  )

  const [modalMsg, setModalMsg] = useState(
    {
      id: -1,
      body: {
        title: constants.STRING_EMPTY,
        message: constants.STRING_EMPTY,
        image: null
      },
      callback: closeModal
    }
  )

  var onTheFlyMessage = null
  var rendered = false;

  const [configurations, setConfigurations] = useState(null);
  const [homeSpecificData, setHomeSpecificData] = useState(null);
  const [wallets, setWallets] = useState([]);

  useEffect(() => {
    setMoment();
    backEndService.loadConfigurations(jsonConfigurationLocation, handleSuccessGetConfiguration, handleErrorGetConfiguration);
    window.addEventListener('popstate', function (event) {
      setSimulateForFullScreenOrigin(constants.STRING_EMPTY);
      setHideMenuForFullScreen(false);
      var intercomIcon = document.getElementsByClassName("intercom-lightweight-app");
      if (intercomIcon) {
        intercomIcon[0].style.visibility = 'visible';
      }
    })
  },
    // eslint-disable-next-line
    []);

  // eslint-disable-next-line
  useEffect(() => {

    if (hideMenuForFullScreen && simulateForFullScreenOrigin !== window.location.href) {
      setSimulateForFullScreenOrigin(constants.STRING_EMPTY);
      setHideMenuForFullScreen(false);
      var intercomIcon = document.getElementsByClassName("intercom-lightweight-app");
      if (intercomIcon) {
        intercomIcon[0].style.visibility = 'visible';
      }
    }
  }
  )

  function setMoment(value) {
    var newLang = value;
    if (newLang === undefined) {
      newLang = lang;
    }
    if (newLang.startsWith('fr-')) {
      moment.locale('fr')
    } else if (newLang.startsWith('pt-')) {
      moment.locale('pt')
    } else {
      moment.locale('en')
    }
  }

  function closeModal() {

    setModalMsg({
      id: -1,
      body: {
        title: constants.STRING_EMPTY,
        message: constants.STRING_EMPTY,
        image: null
      },
      callback: null
    })

  }

  function clearMaintenance() {
    if (maintenance) {
      setMaintenance(false);
      setMaintenanceAllowLogin(false);
    }
  }
  //Handler for Callback of the Text for the UI
  function handleSuccessGetConfiguration(data) {
    setConfigurations(data);
    if (data.debugMode) {
      document.documentElement.setAttribute('debug', 'true');
    }
  }


  function handleErrorGetConfiguration(data) {
    console.log('handleFatalError');
  }


  useEffect(() => {
    if (onTheFlyMessage) {
      if (!renderLoopInProgress) {
        //setRenderLoopInProgress(true);
        // eslint-disable-next-line
        renderLoopInProgress = true;
        setModalMsg(onTheFlyMessage);
      }
    }
    // eslint-disable-next-line
    rendered = true;
  });

  function doIShowWalletsMenu() {
    var toReturn = style.noShow;
    if (displayWalletsMenu) {
      toReturn = style.show;
    }
    return toReturn;
  }

  function doIShowUserMenu() {
    var toReturn = style.noShow;
    if (displayUserMenu) {
      toReturn = style.show;
    }
    return toReturn;
  }

  function toggleUserMenu(force) {

    if (force !== undefined && force !== null) {
      setDisplayUserMenu(force);
    } else {
      setDisplayUserMenu(!displayUserMenu);
    }
  }

  function toggleWalletsMenu(force) {

    if (force !== undefined && force !== null) {
      setDisplayWalletsMenu(force);
    } else {
      setDisplayWalletsMenu(!displayWalletsMenu);
    }
  }

  function showLeftMenu() {
    if (isMobile()) {
      return false;
    } else {
      return (getFromLocalSetting("LEFTMENU_SHOWED") === 'true' || !getFromLocalSetting("LEFTMENU_SHOWED"))
    }
  }

  function setDisplayLeftMenu(value) {
    saveToLocalSetting("LEFTMENU_SHOWED", value ? 'true' : 'false');
    checkDisplayLeftMenu(value);
  }

  function isMobile() {
    return window.innerWidth <= constants.MOBILE_SCREEN_SIZE_WIDTH_PIXEL;
  }


  function simulateFullScreen(currentLocation) {
    setSimulateForFullScreenOrigin(window.location.href);
    setHideMenuForFullScreen(true);
    var intercomIcon = document.getElementsByClassName("intercom-lightweight-app");
    if (intercomIcon) {
      intercomIcon[0].style.visibility = 'hidden';
    }
    //Simulate full Screen By removing Menus
  }

  function displayModalLogin(forgetPasswordAsked) {
    const theMsg = {
      component: <Login key={Math.random()} forgetPasswordAsked={forgetPasswordAsked} />,
      id: Math.random(),
      body: {
        title: constants.STRING_EMPTY,
        message: constants.STRING_EMPTY,
        image: constants.STRING_EMPTY
      },
      callback: closeModal,
      showClose: 'false',
      width: '640px'
    };
    displayGenericModal(theMsg)
  }

  function displayModalRegisterFromStreamer(params, searchParams) {
    displayModalRegister(params, searchParams, true);
  }

  function displayModalRegister(params, searchParams, sponsorCode) {
    const theMsg = {
      component: <Register sponsorCode={sponsorCode ? params.hash : null} urlParams={params} searchParams={searchParams} key={Math.random()} />,
      id: Math.random(),
      body: {
        title: constants.STRING_EMPTY,
        message: constants.STRING_EMPTY,
        image: constants.STRING_EMPTY
      },
      callback: closeModal,
      showClose: 'false',
      width: '640px',
      //height: '545px'
    };
    displayGenericModal(theMsg)
  }

  function displayFullScreenModalComponent(component) {
    const theMsg = {
      component: component,
      id: Math.random(),
      body: {
        title: constants.STRING_EMPTY,
        message: constants.STRING_EMPTY,
        image: constants.STRING_EMPTY
      },
      callback: closeModal,
      height: 'full'
    };
    displayGenericModal(theMsg)
  }

  function displayModalComponent(component) {
    const theMsg = {
      component: component,
      id: Math.random(),
      body: {
        title: constants.STRING_EMPTY,
        message: constants.STRING_EMPTY,
        image: constants.STRING_EMPTY
      },
      callback: closeModal
    };
    displayGenericModal(theMsg)
  }

  function displayModalMsg(isHtml, title, message, img, callback) {
    var body =
    {
      isHtml: isHtml,
      title: title,
      message: isHtml ? message.html : message,
      height: isHtml ? (message.height ? message.height : '100%') : '100%',
      img: img
    }

    var theCallBack = callback;

    if (theCallBack === undefined || theCallBack === null) {
      theCallBack = closeModal;
    }
    const theMsg = {
      id: Math.random(),
      body: body,
      callback: theCallBack
    };
    displayGenericModal(theMsg)
  }

  function displayGenericModal(theMsg) {
    if (rendered) {
      setModalMsg(theMsg)
    } else {
      onTheFlyMessage = theMsg;
    }
  }

  function resetBorderMenu() {
    setReloadBorderMenu(Math.random());
  }

  function requestHomeSpecificDisplayFromMobile(data) {
    requestHomeSpecificDisplay('MOBILE_MENU', data);
  }

  function resetHomeInfo() {
    setHomeSpecificData(null);
  }
  function requestHomeSpecificDisplay(source, data) {
    setHomeSpecificData(data);
  }

  function displaySearchResultModal(title1, title2, message, img) {
    setSearchMsg({
      id: Math.random(),
      title1: title1,
      title2: title2,
      message: message,
      image: img,
    }
    )
  }

  function handleGameStart() {
    if (isMobile) {
      toggleLeftMenu(false);
    }
  }

  function toggleLeftMenu(force) {
    if (force !== undefined) {
      if (force === false) {
        setReloadBorderMenu(Math.random());
      }

      setDisplayLeftMenu(force);
    } else {
      setDisplayLeftMenu(!displayLeftMenu);
    }
  }

  function getPageStyle() {
    var pageStyle = style.gamePanel_grid_noMenu;

    if (displayLeftMenu && !hideMenuForFullScreen) {
      pageStyle = style.gamePanel_grid_menu;
    }
    return pageStyle
  }

  function changeLanguageRefresh(index, value) {
    changeLanguage(index, value);
    setReloadTopMenu(Math.random());
  }


  function allowLogin() {
    setMaintenanceAllowLogin(true);
  }

  function alertMaintenance() {
    ApiCache.getInstance().removeFromCache('CACHE_CASINO_DATA');
    setMaintenance(true);
  }

  function forceReloadTopMenu() {
    setReloadTopMenu(Math.random());
  }

  function getFreeSpinAvailable() {
    let toReturn = 0;
    for (let i = 0; i < wallets.length; i++) {
      if (wallets[i].currentWallet === 1) {
        toReturn = wallets[i].availableFreeSpin;
      }
    }

    return toReturn;
  }

  function changeLanguage(index, value) {
    ApiPersistence.getInstance().setLanguage(value);
    setLang(value);
    setMoment(value);
  }

  function checkAuthorization(authorization) {
    return canAccess(ApiPersistence.getInstance().getUser(), authorization);
  }

  try {

    if (configurations) {

      return (
        <React.Fragment>
          <ConfigProvider locale={lang}>
            <IntlProvider locale={lang} messages={currentLocale.messages}>
              <BrowserRouter>
                <SnackbarProvider
                  action={(snackbarId) => (
                    <div style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }} onClick={() => closeSnackbar(snackbarId)} />
                  )}
                >
                  <div className={`${style.position_relative}`}>
                    <div className={`${style.user_fixed} ${doIShowUserMenu()}`}>
                      <UserMenu definition={configurations.userMenuDefinition} toggleLeftMenu={toggleLeftMenu} toggleWalletsMenu={toggleWalletsMenu} toggleUserMenu={toggleUserMenu} isMobile={isMobile()} />
                    </div>
                    <div className={`${isMobile() ? style.wallet_fixed_mobile : style.wallet_fixed} ${doIShowWalletsMenu()}`}>
                      <WalletsMenu wallets={wallets} forceReloadTopMenu={forceReloadTopMenu} toggleLeftMenu={toggleLeftMenu} toggleWalletsMenu={toggleWalletsMenu} toggleUserMenu={toggleUserMenu} isMobile={isMobile()} />
                    </div>
                  </div>
                  <TopStickyMenu maintenance={maintenance} maintenanceAllowLogin={maintenanceAllowLogin} forceHide={hideMenuForFullScreen} key={'stickyMenu_' + reloadTopMenu} isMobile={isMobile()} setWallets={setWallets} simulateFullScreen={simulateFullScreen} displayModalLogin={displayModalLogin} displayModalRegister={displayModalRegister} toggleUserMenu={toggleUserMenu} toggleWalletsMenu={toggleWalletsMenu} setLanguage={changeLanguage} toggleLeftMenu={toggleLeftMenu} languages={configurations.languages} definition={configurations.topMenuDefinition} resetBorderMenu={resetBorderMenu} />
                  <div className={`${style.gamePanel_baseDiv}`}>
                    <BorderMenu forceHide={hideMenuForFullScreen} key={'borderMenu' + reloadBorderMenu} toggleWalletsMenu={toggleWalletsMenu} toggleUserMenu={toggleUserMenu} toggleLeftMenu={toggleLeftMenu} setLanguage={changeLanguageRefresh} requestHomeSpecificDisplay={requestHomeSpecificDisplay} displaySearchResultModal={displaySearchResultModal} displayModalMsg={displayModalMsg} isMobile={isMobile()} display={displayLeftMenu} definition={configurations.leftMenuDefinition} />
                    <DefaultModal key={Math.random()} msg={modalMsg} />
                    <ModalSearchController isMobile={isMobile()} closeModal={closeModal} displayComponent={displayModalComponent} getFreeSpinAvailable={getFreeSpinAvailable} handleGameStart={handleGameStart} msg={searchMsg} />
                    <div className={getPageStyle()}>
                      <div className={`${style.gamePanel_gamesurface}`}>
                        <Routes>
                          {/* Public Pages */}
                          {checkAuthorization({ authorization: { access: ACCESS_PUBLIC, typeAccount: [], rols: [] } }) && (
                            <>

                              {/*  WE NEED MANY ROUTE FOR THE HOME PAGE BECAUSE THE BORDER MENU CANNOT RERENDER THE HOME PAGE. THIS IS WHY WHEN WE CALL THE HOME PAGE WITH REDIRECT TO ONE OR ANOTHER*/}
                              <Route path={constants.ROUTE_MAINTENANCE} element={<MaintenancePlayer alertMaintenance={alertMaintenance} />} />
                              <Route path={constants.ROUTE_SLASH} element={<Home key='HOME0' displayComponent={displayModalComponent} forceReloadTopMenu={forceReloadTopMenu} getFreeSpinAvailable={getFreeSpinAvailable} clearMaintenance={clearMaintenance} definition={configurations.gamePageDefinition} resetHomeInfo={resetHomeInfo} specificDisplay={homeSpecificData} resetBorderMenu={resetBorderMenu} closeModal={closeModal} displayModalMsg={displayModalMsg} />} />
                              <Route path={constants.ROUTE_HOME} element={<Home key='HOME1' displayComponent={displayModalComponent} forceReloadTopMenu={forceReloadTopMenu} getFreeSpinAvailable={getFreeSpinAvailable} clearMaintenance={clearMaintenance} definition={configurations.gamePageDefinition} resetHomeInfo={resetHomeInfo} specificDisplay={homeSpecificData} resetBorderMenu={resetBorderMenu} closeModal={closeModal} displayModalMsg={displayModalMsg} />} />
                              <Route path={constants.ROUTE_HOME_BACKUP} element={<Home key='HOME2' displayComponent={displayModalComponent} forceReloadTopMenu={forceReloadTopMenu} getFreeSpinAvailable={getFreeSpinAvailable} clearMaintenance={clearMaintenance} definition={configurations.gamePageDefinition} resetHomeInfo={resetHomeInfo} specificDisplay={homeSpecificData} resetBorderMenu={resetBorderMenu} closeModal={closeModal} displayModalMsg={displayModalMsg} />} />
                              <Route path={constants.ROUTE_LOGIN} element={<Home key='LOGIN' displayComponent={displayModalComponent} forceReloadTopMenu={forceReloadTopMenu} getFreeSpinAvailable={getFreeSpinAvailable} onLoad={displayModalLogin} definition={configurations.gamePageDefinition} resetHomeInfo={resetHomeInfo} specificDisplay={homeSpecificData} resetBorderMenu={resetBorderMenu} />} />
                              <Route path={constants.ROUTE_LOGIN_BACKUP} element={<Home key='LOGIN2' displayComponent={displayModalComponent} forceReloadTopMenu={forceReloadTopMenu} getFreeSpinAvailable={getFreeSpinAvailable} onLoad={displayModalLogin} definition={configurations.gamePageDefinition} resetHomeInfo={resetHomeInfo} specificDisplay={homeSpecificData} resetBorderMenu={resetBorderMenu} />} />

                              <Route path={constants.ROUTE_FORGOT_PASSWORD} element={<Home key={'FORGETPASSWORD'} displayComponent={displayModalComponent} forceReloadTopMenu={forceReloadTopMenu} getFreeSpinAvailable={getFreeSpinAvailable} onLoad={() => displayModalLogin(true)} definition={configurations.gamePageDefinition} resetHomeInfo={resetHomeInfo} specificDisplay={homeSpecificData} resetBorderMenu={resetBorderMenu} />} />
                              <Route path={constants.ROUTE_FORGOT_PASSWORD_BACKUP} element={<Home key={'FORGETPASSWORD2'} displayComponent={displayModalComponent} forceReloadTopMenu={forceReloadTopMenu} getFreeSpinAvailable={getFreeSpinAvailable} onLoad={() => displayModalLogin(true)} definition={configurations.gamePageDefinition} resetHomeInfo={resetHomeInfo} specificDisplay={homeSpecificData} resetBorderMenu={resetBorderMenu} />} />

                              <Route path={constants.ROUTE_VERIFICATION} element={<Home key={'VERIFICATION'} displayComponent={displayModalComponent} forceReloadTopMenu={forceReloadTopMenu} getFreeSpinAvailable={getFreeSpinAvailable} onLoad={displayModalRegister} definition={configurations.gamePageDefinition} resetHomeInfo={resetHomeInfo} specificDisplay={homeSpecificData} resetBorderMenu={resetBorderMenu} />} />
                              <Route path={constants.ROUTE_VERIFICATION_BACKUP} element={<Home key={'VERIFICATION2'} displayComponent={displayModalComponent} forceReloadTopMenu={forceReloadTopMenu} getFreeSpinAvailable={getFreeSpinAvailable} onLoad={displayModalRegister} definition={configurations.gamePageDefinition} resetHomeInfo={resetHomeInfo} specificDisplay={homeSpecificData} resetBorderMenu={resetBorderMenu} />} />


                              <Route path={constants.ROUTE_REGISTER} element={<Home key={'REGISTER'} displayComponent={displayModalComponent} forceReloadTopMenu={forceReloadTopMenu} getFreeSpinAvailable={getFreeSpinAvailable} onLoad={displayModalRegister} definition={configurations.gamePageDefinition} resetHomeInfo={resetHomeInfo} specificDisplay={homeSpecificData} resetBorderMenu={resetBorderMenu} />} />
                              <Route path={constants.ROUTE_REGISTER_BACKUP} element={<Home key={'REGISTER2'} displayComponent={displayModalComponent} forceReloadTopMenu={forceReloadTopMenu} getFreeSpinAvailable={getFreeSpinAvailable} onLoad={displayModalRegister} definition={configurations.gamePageDefinition} resetHomeInfo={resetHomeInfo} specificDisplay={homeSpecificData} resetBorderMenu={resetBorderMenu} />} />

                              <Route path={constants.ROUTE_REGISTER_WITH_HASH} element={<Home key='REGISTER' displayComponent={displayModalComponent} forceReloadTopMenu={forceReloadTopMenu} getFreeSpinAvailable={getFreeSpinAvailable} onLoad={displayModalRegister} definition={configurations.gamePageDefinition} resetHomeInfo={resetHomeInfo} specificDisplay={homeSpecificData} resetBorderMenu={resetBorderMenu} />} />
                              <Route path={constants.ROUTE_REGISTER_FROM_STREAMER} element={<Home key='REGISTER' displayComponent={displayModalComponent} forceReloadTopMenu={forceReloadTopMenu} getFreeSpinAvailable={getFreeSpinAvailable} onLoad={displayModalRegisterFromStreamer} definition={configurations.gamePageDefinition} resetHomeInfo={resetHomeInfo} specificDisplay={homeSpecificData} resetBorderMenu={resetBorderMenu} />} />
                              <Route path={constants.ROUTE_HOME_CASINO} element={<Home key='HOME_SLOT' subSection='slots' displayComponent={displayModalComponent} forceReloadTopMenu={forceReloadTopMenu} getFreeSpinAvailable={getFreeSpinAvailable} definition={configurations.gamePageDefinition} resetHomeInfo={resetHomeInfo} specificDisplay={homeSpecificData} resetBorderMenu={resetBorderMenu} />} />
                              <Route path={constants.ROUTE_HOME_LIVEDEALER} element={<Home key='HOME_LIVE' subSection='live_casino' displayComponent={displayModalComponent} forceReloadTopMenu={forceReloadTopMenu} getFreeSpinAvailable={getFreeSpinAvailable} definition={configurations.gamePageDefinition} resetHomeInfo={resetHomeInfo} specificDisplay={homeSpecificData} resetBorderMenu={resetBorderMenu} />} />


                              {/* } <Route path={constants.ROUTE_REGISTER} element={<SystemRegister />} />
                            <Route path={constants.ROUTE_REGISTER} element={<Register key={Math.random()} displayComponent={displayModalComponent} />} />
                            */}
                              <Route path={constants.ROUTE_ABOUT} element={<AboutUs />} />
                              <Route path={constants.ROUTE_BETTING_RULES} element={<BettingRules />} />
                              <Route path={constants.ROUTE_BONUS_TERMS} element={<BonusTerms />} />
                              <Route path={constants.ROUTE_CONTACT} element={<ContactUs />} />
                              <Route path={constants.ROUTE_FAQ} element={<FAQ />} />
                              <Route path={constants.ROUTE_PRIVACY_POLICY} element={<PrivacyPolicy />} />
                              <Route path={constants.ROUTE_TERMS_AND_CONDITIONS} element={<TermsConditions />} />
                              <Route path={constants.ROUTE_404} element={<System404 />} />
                              <Route path={constants.ROUTE_500} element={<System500 />} />

                              {/*
                              <Route path={constants.ROUTE_RESET_PASSWORD} element={<SystemResetPassword />} />
                              <Route path={constants.ROUTE_CHANGE_EMAIL} element={<SystemChangeMail />} />
                              <Route path={constants.ROUTE_REGISTER_LANDING} element={<SystemRegisterNoHeader />} />
                              */}
                              <Route path={constants.ROUTE_RESET_PASSWORD} element={<SystemResetPassword />} />
                              <Route path={constants.ROUTE_RESPONSBILE_GAMING} element={<ResponsibleGambling />} />
                              <Route path={constants.ROUTE_USERLOGOUT} element={<Logout test='1' />} />
                              <Route path={constants.ROUTE_LOGOUT} element={<Logout test='2' />} />
                              <Route path={constants.ROUTE_440} element={<Logout type={440} />} />
                              <Route path={constants.ROUTE_PLAY_GAME} element={<PlayGame isSimulatedFullScreen={hideMenuForFullScreen} key={'PLAYGAME'} refreshWallets={forceReloadTopMenu} isMobile={isMobile()} />} />
                              <Route path={constants.ROUTE_PLAY_GAME_BACKUP} element={<PlayGame isSimulatedFullScreen={hideMenuForFullScreen} key={'PLAYGAME2'} refreshWallets={forceReloadTopMenu} isMobile={isMobile()} />} />
                              <Route path={constants.ROUTE_CONTEST} element={<ShowContestWinners lang={lang} />} />
                            </>
                          )}

                          {/* Need to be Logged Pages */}
                          {checkAuthorization({ authorization: { access: ACCESS_PRIVATE, typeAccount: [], rols: [] } }) && (
                            <>
                              <Route path={constants.ROUTE_REFER_A_FRIEND} element={<ReferFriendWager />} />
                              <Route path={constants.ROUTE_PROFILE} element={<Profile closeModal={closeModal} displayGenericModal={displayGenericModal} />} />
                              <Route path={constants.ROUTE_CASHIER} element={<Cashier refreshWallets={forceReloadTopMenu} userType={ApiPersistence.getInstance().getUser().typeAccount} isMobile={isMobile()} />} />
                              <Route path={constants.ROUTE_LOYALTY} element={<Loyalty closeModal={closeModal} displayGenericModal={displayGenericModal} displayComponent={displayModalComponent} displayFullScreenModalComponent={displayFullScreenModalComponent} refreshWallets={forceReloadTopMenu} userType={ApiPersistence.getInstance().getUser().typeAccount} isMobile={isMobile()} />} />
                              <Route path={constants.ROUTE_RESPONSBILE_GAMING_DEPOSIT_LINIT} element={<DepositLimit />} />
                              <Route path={constants.ROUTE_RESPONSBILE_GAMING_LOSSLIMIT} element={<LossLimit />} />
                              <Route path={constants.ROUTE_RESPONSBILE_GAMING_TAKE_A_BREAK} element={<TakeABreak />} />
                              <Route path={constants.ROUTE_RESPONSBILE_GAMING_ACTIVITY_CHECK} element={<ActivityCheck />} />
                              <Route path={constants.ROUTE_RESPONSBILE_GAMING_SELF_EXCLUSION} element={<SelfExclusion />} />
                            </>
                          )}

                          {/* Need admin Rights */}
                          {checkAuthorization({ authorization: { access: ACCESS_PRIVATE, typeAccount: [USERTYPE_ADMIN], rols: [] } }) && (
                            <>
                              <Route path={constants.ROUTE_ADMIN_PROMOTIONS} element={<Promotions closeModal={closeModal} displayGenericModal={displayGenericModal} />} />
                              <Route path={constants.ROUTE_ADMIN_PROMOTION_DETAIL} element={<Promotion closeModal={closeModal} displayModalMsg={displayModalMsg} />} />
                              <Route path={constants.ROUTE_ADMIN_PROMOTION_NEW} element={<NewPromotion closeModal={closeModal} displayModalMsg={displayModalMsg} />} />

                              <Route path={constants.ROUTE_ADMIN_GIVEAWAYS} element={<GiveAways />} />
                              <Route path={constants.ROUTE_USER_MANAGEMENT} element={<UserManagement closeModal={closeModal} displayGenericModal={displayGenericModal} />} />
                              <Route path={constants.ROUTE_ADMIN_USERDATA} element={<UserdataWrapper closeModal={closeModal} displayGenericModal={displayGenericModal} />} />
                              <Route path={constants.ROUTE_CONTEST_WINNERS} element={<ContestList />} />
                              <Route path={constants.ROUTE_INFO_POPUP} element={<InfoPopupList />} />
                              <Route path={constants.ROUTE_INFO_POPUP_DETAIL} element={<EditInfoPopup closeModal={closeModal} displayModalMsg={displayModalMsg} />} />
                              <Route path={constants.ROUTE_CONTEST_WINNERS_DETAIL} element={<EditContest />} />
                            </>
                          )}

                          {/* Need admin Rights with role 99*/}
                          {checkAuthorization({ authorization: { access: ACCESS_PRIVATE, typeAccount: [USERTYPE_ADMIN], rols: [] } }) && (
                            <>
                              <Route path={constants.ROUTE_ADMIN_GAMES} element={<ManageGames displayComponent={displayModalComponent} />} />
                            </>
                          )}

                          {/* Need streamer Rights */}
                          {checkAuthorization({ authorization: { access: ACCESS_PRIVATE, typeAccount: [USERTYPE_STREAMER], rols: [] } }) && (
                            <>
                              <Route path={constants.ROUTE_STREAMING_DEAL} element={<StreamerActions forceReloadTopMenu={forceReloadTopMenu} />} />
                            </>
                          )}
                          <Route path={"/test"} element={<GenericTest definition={configurations.gamePageDefinition} />} />
                          <Route path="*" element={<Logout test='3' />} />
                        </Routes>

                      </div>
                      <MobileBottomMenu forceHide={hideMenuForFullScreen} key={'mobileMenu'} toggleWalletsMenu={toggleWalletsMenu} toggleUserMenu={toggleUserMenu} requestHomeSpecificDisplay={requestHomeSpecificDisplayFromMobile} toggleLeftMenu={toggleLeftMenu} definition={configurations.mobileBottomMenuDefinition} />
                      <PageFooter maintenance={maintenance} allowLogin={allowLogin} forceHide={hideMenuForFullScreen} resetBorderMenu={resetBorderMenu} />
                    </div>
                  </div>
                </SnackbarProvider>
              </BrowserRouter>
            </IntlProvider>
          </ConfigProvider>
        </React.Fragment >
      );
    }

  } catch (error) {
    console.log('ERROR: ' + error.message);
  }

}

export default (HomePage)
