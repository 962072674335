export function stacktrace(title) {
    var err = new Error();
    trace(err.stack, title);
}
export function trace(toLog, title) {

    if (isDebugMode) {
        var mainTitle = title;
        if (!mainTitle) {
            mainTitle = 'TRACE'
        }

        console.log('-- ' + mainTitle + ' --');
        console.log(toLog);
    }
}

function isDebugMode() {
    var debug = document.documentElement.getAttribute('debug') === 'true';
    return (isLocalServer() || debug)
}

function isLocalServer() {
    if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
        return true;
    }

    return false;
}