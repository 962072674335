import React, { useEffect, useState } from 'react';

import Button from 'newui/Generic/Inputs/Button';
import style from './style.module.scss'
import { useLocation, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl'
import * as jwtBackendService from 'newui/Services/JWTBackend/jwtBackendService'
import { displayGenericErrorNotificationIntl } from 'newui/Utils/uiUtil';
import { getImageUrl } from 'newui/Utils/uiUtil';

function Documents(props) {

    const navigate = useNavigate();
    const location = useLocation();
    const intl = useIntl();

    let [imgSrc, setImgSrc] = useState(null)
    let [imgFormat, setImgFormat] = useState(null)
    let [img2Src, setImg2Src] = useState(null)
    let [img2Format, setImg2Format] = useState(null)

    useEffect(() => {
        loadImage(1);
        loadImage(2);
    },// eslint-disable-next-line
        [])

    function loadImage(type) {
        let succ = loadImageSuccess;
        let fail = loadImageFailure;
        if (type === 2) {
            succ = loadImage2Success;
            fail = loadImage2Failure;
        }
        jwtBackendService.getImageFile(type, succ, fail, navigate, location.pathname);
    }

    function loadImageSuccess(data) {
        if (data.doc64) {
            setImgSrc(Buffer.from(data.doc64, "base64").toString());
            setImgFormat(data.format);
        }
    }

    function loadImageFailure(data) {
        displayGenericErrorNotificationIntl(intl);
    }

    function loadImage2Success(data) {
        if (data.doc64) {
            setImg2Src(Buffer.from(data.doc64, "base64").toString());
            setImg2Format(data.format);
        }
    }

    function loadImage2Failure(data) {
        displayGenericErrorNotificationIntl(intl);
    }

    function handleFileUpload(event, docType) {
        let succ = uploadSuccess;
        let fail = uploadFailure;

        if (docType === 2) {
            succ = upload2Success;
            fail = upload2Failure;
        }

        event.preventDefault();

        if (event.dataTransfer && event.dataTransfer.items) {
            if (event.dataTransfer.items.length > 1) {
                console.log('More than one file');
                return;
            }

            if (event.dataTransfer.items[0].kind === 'file') {
                const file = event.dataTransfer.items[0];
                jwtBackendService.uploadProofImageFile(file.getAsFile(), docType, succ, fail, navigate, location.pathname);
            }
        } else if (event.target.files) {
            console.log(event.target.files[0])
            if (event.target.files.length > 1) {
                console.log('More than one file');
                return;
            }

            const file = event.target.files[0];

            jwtBackendService.uploadProofImageFile(file, docType, succ, fail, navigate, location.pathname);
        }
    }

    function uploadSuccess(data) {
        loadImage(1);
    }

    function uploadFailure(data) {
        displayGenericErrorNotificationIntl(intl);
    }

    function upload2Success(data) {
        loadImage(2);
    }

    function upload2Failure(data) {
        displayGenericErrorNotificationIntl(intl);
    }

    function getImageSrc(type) {
        let toReturn = getImageUrl('newui/general/noImage.png');
        if (type === 1 && imgSrc && imgFormat) {
            return "data:" + imgFormat + ";base64," + imgSrc
        } else if (type === 2 && img2Src && img2Format) {
            return "data:" + img2Format + ";base64," + img2Src
        }

        return toReturn;
    }

    return (
        <>
            <div className={style.imagesGrid}>
                <div>
                    <div className={style.allCenter}><div className={style.photoTitle}>{intl.formatMessage({ id: 'newcasino.profile.password.doc.proof1.title', defaultMessage: '' })}</div></div>
                    <div className={style.allCenter}><div className={style.photoSubTitle}>{intl.formatMessage({ id: 'newcasino.profile.password.doc.proof1.desc', defaultMessage: '' })}</div></div>
                    <div className={style.allCenter}><div className={style.imageDiv}><img className={style.theImage} src={getImageSrc(1)} alt='' /></div></div>
                    <input className={style.fileInput} type="file" id="uploadMyFile" name="myfile1" onChange={(e) => handleFileUpload(e, 1)} />
                    {props.personalData.is_full_kyc_done !== 1 &&
                        <div className={style.buttCenter}><Button width='250px' colorStyle='light' value={intl.formatMessage({ id: 'newcasino.profile.password.doc.select', defaultMessage: '' })} onClick={() => document.getElementById('uploadMyFile').click()} /></div>

                    }
                </div>
                <div>
                    <div className={style.allCenter}><div className={style.photoTitle}>{intl.formatMessage({ id: 'newcasino.profile.password.doc.proof2.title', defaultMessage: '' })}</div></div>
                    <div className={style.allCenter}><div className={style.photoSubTitle}>{intl.formatMessage({ id: 'newcasino.profile.password.doc.proof2.desc', defaultMessage: '' })}</div></div>
                    <div className={style.allCenter}><div className={style.imageDiv}><img className={style.theImage} src={getImageSrc(2)} alt='' /></div></div>
                    <input className={style.fileInput} type="file" id="upload2MyFile" name="myfile2" onChange={(e) => handleFileUpload(e, 2)} />
                    {props.personalData.is_full_kyc_done !== 1 &&
                        <div className={style.buttCenter}><Button width='250px' colorStyle='light' value={intl.formatMessage({ id: 'newcasino.profile.password.doc.select', defaultMessage: '' })} onClick={() => document.getElementById('upload2MyFile').click()} /></div>
                    }
                </div>
            </div>
        </>
    );
}

export default Documents;