import React from 'react'
import style from './style.module.scss'
import Button from 'newui/Generic/Inputs/Button';
import SpanClick from 'newui/Generic/Inputs/SpanClick';



function Footer(props) {

  function buttonClicked(button) {
    var action = button.action;
    var canProcess = button.canProcess;

    if (canProcess === undefined && action) {
      action();
    }

    if (canProcess === true && action) {
      action();
    }

  }

  try {
    return (
      <>
        <div className={style.mainDiv}>
          {((props.buttonLeft !== undefined) && (props.buttonRight === undefined)) &&
            <div className={style.mainDivButtonSingle}>
              <div className={style.single}>
                <Button colorStyle='dark' chevron={props.buttonLeft.chevron} value={props.buttonLeft.title} onClick={() => buttonClicked(props.buttonLeft)} />
              </div>
            </div>
          }
          {(props.buttonLeft && props.buttonRight) &&
            <div className={style.mainDivButton}>
              <div>
                <Button colorStyle='dark' chevron={props.buttonLeft.chevron} value={props.buttonLeft.title} onClick={() => buttonClicked(props.buttonLeft)} />
              </div>
              <div className={style.alignRight}>
                <Button colorStyle='light' value={props.buttonRight.title} active={props.buttonRight.canProcess} onClick={() => buttonClicked(props.buttonRight)} />
              </div>
            </div>
          }
          {props.subTitle &&
            <div className={style.spacer16}>
              <span className={style.bottomText}>{props.subTitle.text}</span> <SpanClick className={style.bottomLink} onClick={props.subTitle.linkAction}>{props.subTitle.link}</SpanClick>
            </div>
          }
        </div >
      </>
    );

  } catch (error) {
    console.log('ERROR: ' + error.message);
    return '';
  }
}

export default Footer