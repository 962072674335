import React, { useState } from 'react'
import style from './style.module.scss'
import TextField from 'newui/Generic/Inputs/TextField';
import { isEmail, isPasswordOk, isTextMin5Char } from 'newui/Utils/uiUtil';
import Checkbox from 'newui/Generic/Inputs/Checkbox';
import { useIntl } from 'react-intl';

export const DATA_USERPASSWORD_EMAIL = 'email'
export const DATA_USERPASSWORD_PASSWORD = 'password'
export const DATA_USERPASSWORD_LICENSE = 'license'
export const DATA_USERPASSWORD_PROMOCODE = 'promocode'

function UserPassword(props) {

  const intl = useIntl();
  const sourceData = props.value;
  const sourceEmail = sourceData.fields.get(DATA_USERPASSWORD_EMAIL) ? sourceData.fields.get(DATA_USERPASSWORD_EMAIL) : {};
  const sourcePassword = sourceData.fields.get(DATA_USERPASSWORD_PASSWORD) ? sourceData.fields.get(DATA_USERPASSWORD_PASSWORD) : {};
  const sourceLicense = sourceData.fields.get(DATA_USERPASSWORD_LICENSE) ? sourceData.fields.get(DATA_USERPASSWORD_LICENSE) : {};
  const sourcePromoCode = sourceData.fields.get(DATA_USERPASSWORD_PROMOCODE) ? sourceData.fields.get(DATA_USERPASSWORD_PROMOCODE) : {};

  const usePromocode = (props.hasPromocode === true);

  const [email, setEmail] = useState(sourceEmail.value ? sourceEmail.value : '');
  const [promoCode, setPromoCode] = useState(sourcePromoCode.value ? sourcePromoCode.value : '');
  const [password, setPassword] = useState(sourcePassword.value ? sourcePassword.value : '');
  const [license, setLicense] = useState(sourceLicense.value ? sourceLicense.value : '');
  const [emailValid, setEmailValid] = useState((sourceEmail.valid !== undefined) ? sourceEmail.valid : null);
  const [promoCodeValid, setPromoCodeValid] = useState((sourcePromoCode.valid !== undefined) ? sourcePromoCode.valid : (usePromocode ? null : true));
  const [passwordValid, setPasswordValid] = useState((sourcePassword.valid !== undefined) ? sourcePassword.valid : null);
  const [licenseValid, setLicenseValid] = useState((sourceLicense.valid !== undefined) ? sourceLicense.valid : null);


  function emailValidCallBack(isValid, value) {
    var data = new Map();
    data.set(DATA_USERPASSWORD_EMAIL, { value: value, valid: isValid });
    data.set(DATA_USERPASSWORD_PASSWORD, { value: password, valid: passwordValid });
    data.set(DATA_USERPASSWORD_PROMOCODE, { value: promoCode, valid: promoCodeValid });
    data.set(DATA_USERPASSWORD_LICENSE, { value: license, valid: licenseValid });
    setEmailValid(isValid);
    setEmail(value)
    props.validatorInformer((licenseValid && promoCodeValid && passwordValid && isValid), data);
  }

  function promoCodeValidCallBack(isValid, value) {
    var data = new Map();
    data.set(DATA_USERPASSWORD_EMAIL, { value: email, valid: emailValid });
    data.set(DATA_USERPASSWORD_PASSWORD, { value: password, valid: passwordValid });
    data.set(DATA_USERPASSWORD_PROMOCODE, { value: value, valid: isValid });
    data.set(DATA_USERPASSWORD_LICENSE, { value: license, valid: licenseValid });
    setPromoCodeValid(isValid);
    setPromoCode(value);
    props.validatorInformer((licenseValid && isValid && passwordValid && emailValid), data);
  }

  function passwordValidCallBack(isValid, value) {
    var data = new Map();
    data.set(DATA_USERPASSWORD_EMAIL, { value: email, valid: emailValid });
    data.set(DATA_USERPASSWORD_PASSWORD, { value: value, valid: isValid });
    data.set(DATA_USERPASSWORD_PROMOCODE, { value: promoCode, valid: promoCodeValid });
    data.set(DATA_USERPASSWORD_LICENSE, { value: license, valid: licenseValid });
    setPasswordValid(isValid);
    setPassword(value);
    props.validatorInformer((licenseValid && promoCodeValid && isValid && emailValid), data);
  }

  function showTerms() {
    props.showTerms()
  }

  function showPolicy() {
    props.showPolicy()
  }

  function licenseCallBack(value) {
    var data = new Map();
    var fieldValid = (value === true)
    data.set(DATA_USERPASSWORD_EMAIL, { value: email, valid: emailValid });
    data.set(DATA_USERPASSWORD_PASSWORD, { value: password, valid: passwordValid });
    data.set(DATA_USERPASSWORD_PROMOCODE, { value: promoCode, valid: promoCodeValid });
    data.set(DATA_USERPASSWORD_LICENSE, { value: value, valid: fieldValid });
    setLicense(value);
    setLicenseValid(fieldValid);
    props.validatorInformer((fieldValid && promoCodeValid && passwordValid && emailValid), data);
  }

  try {
    return (
      <div className={style.mainDiv}>
        <div className={style.content}>
          <div className={style.test}>
            {usePromocode &&
              <div className={style.test2}>
                <TextField maxLength='100' autofocus='true' values={[promoCode, promoCodeValid]} hint={intl.formatMessage({ id: 'newcasino.auth.registration.cred.promotion.hint', defaultMessage: 'Type your promotion code here' })} label={intl.formatMessage({ id: 'newcasino.auth.registration.cred.promotion.label', defaultMessage: 'Promotion Code' })} validator={isTextMin5Char} validatorInformer={promoCodeValidCallBack} mandatory='true' showTitleError='true' showCheck='true' validationMsg={intl.formatMessage({ id: 'newcasino.auth.registration.cred.promotion.error', defaultMessage: 'Invalid Promotion Code' })} />
              </div>
            }
            <div className={style.test2}>
              <TextField maxLength='100' autofocus='true' values={[email, emailValid]} hint={intl.formatMessage({ id: 'newcasino.auth.registration.cred.email.hint', defaultMessage: 'Type your email here' })} label={intl.formatMessage({ id: 'newcasino.auth.registration.cred.email.label', defaultMessage: 'Email' })} validator={isEmail} validatorInformer={emailValidCallBack} mandatory='true' showTitleError='true' showCheck='true' validationMsg={intl.formatMessage({ id: 'newcasino.auth.registration.cred.email.error', defaultMessage: 'Invalid Email' })} />
            </div>
            <div className={style.test2}>
              <TextField maxLength='100' values={[password, passwordValid]} hint={intl.formatMessage({ id: 'newcasino.auth.registration.cred.password.hint', defaultMessage: 'Type your new password here' })} type='password' canSee='true' mandatory='true' label={intl.formatMessage({ id: 'newcasino.auth.registration.cred.password.label', defaultMessage: 'Password' })} validator={isPasswordOk} showTitleError='true' showCheck='true' validatorInformer={passwordValidCallBack} validationMsg={intl.formatMessage({ id: 'newcasino.auth.registration.cred.password.error', defaultMessage: 'You need at less 8 characters with a mix of uppercase and lowercase' })} />
            </div>
            <div className={style.test2}>
              <Checkbox label={[
                { label: (intl.formatMessage({ id: 'newcasino.auth.registration.cred.license1', defaultMessage: 'I agree to the' })+' ') },
                { label: (intl.formatMessage({ id: 'newcasino.auth.registration.cred.license2', defaultMessage: 'terms and conditions' })+' '), action: showTerms },
                { label: (intl.formatMessage({ id: 'newcasino.auth.registration.cred.license3', defaultMessage: 'and' })+' ') },
                { label: intl.formatMessage({ id: 'newcasino.auth.registration.cred.license4', defaultMessage: 'privacy policy *' }), action: showPolicy }
              ]} checked={license} onClick={licenseCallBack} />
            </div>
          </div>
        </div>
      </div>
    );

  } catch (error) {
    console.log('ERROR: ' + error.message);
    return '';
  }
}

export default UserPassword