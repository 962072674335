/* eslint-disable */
import React, { useEffect, useState } from 'react';
import style from './style.module.scss'
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import * as jwtBackendService from 'newui/Services/JWTBackend/jwtBackendService';
import Header from 'newui/Casino/General/Modal/Header';
import Footer from 'newui/Casino/General/Modal/Footer';
import TextField from 'newui/Generic/Inputs/TextField';
import { displayGenericErrorNotificationIntl, displayInfoNotification, displayInfoNotificationIntl, getAmountTxt, getAmountTxtWithCoin } from 'newui/Utils/uiUtil';
import Button from 'newui/Generic/Inputs/Button';


function UseFreeSpin(props) {

    const intl = useIntl()
    const navigate = useNavigate();
    const location = useLocation();

    const [amount, setAmount] = useState('');

    function doAction() {
        jwtBackendService.useFreeSpins(props.gameId, props.nbrFreeSpins, successAction, failureAction, navigate, location.pathname);
    }

    function successAction(data) {
        props.callback(props.gameId)
        /*
                if (amount > 0) {
                    displayInfoNotification(intl.formatMessage({ id: 'newcasino.info.title' }), intl.formatMessage({ id: 'newcasino.admin.money.add.success' }, { AMOUNT: (getAmountTxtWithCoin(amount, props.coin)) }));
                } else {
                    displayInfoNotification(intl.formatMessage({ id: 'newcasino.info.title' }), intl.formatMessage({ id: 'newcasino.admin.money.remove.success' }, { AMOUNT: (getAmountTxtWithCoin(Math.abs(amount), props.coin)) }));
                }
        
                props.reload();
                props.closeModal();
                */
    }

    function failureAction(data) {
        displayGenericErrorNotificationIntl(intl);
        props.callback(props.gameId)
    }

    function addFreeSpin(data) {
        if (data) {
            doAction();
        } else {
            props.callback(props.gameId)
        }
    }

    try {

        return (
            <>
                <div className={style.headerTitle}>
                    FreeSpins Available
                </div>
                <div className={style.headerSubTitle}>
                    You have <b>{props.nbrFreeSpins}</b> freespins available, do you want to use them on this game?
                </div>
                <div className={style.grid50}>
                    <div className={style.buttonResponse}><Button value='Yes' onClick={() => addFreeSpin(true)} /></div>
                    <Button value='No' onClick={() => addFreeSpin(false)} />
                </div>
            </>
        );

    } catch (error) {
        console.log('ERROR: ' + error.message);
        return '';
    }
}

export default UseFreeSpin;
